import React, { useState, useEffect } from 'react'
import { graphql } from 'gatsby'
import { Grid, Box } from 'react-raster'
import gridProps from 'layouts/gridProps'
import useWindowSize from 'utils/useWindowSize'
import { useInView } from 'react-intersection-observer'
import SEO from '../seo'

const StudioElement = ({ headline, text, fontsize, index }) => {
  const [ref, animate] = useInView({
    threshold: 0,
    rootMargin: '0px',
    triggerOnce: true
  })

  return (
    <Box
      className='studio-element'
      ref={ref}
    >
      <Box
        // bottom={index === 0 ? 1 : 2}
        bottom={2}
        style={`
          transition: transform 1s;
          transform: translate3d(0, ${animate ? '0vh' : '20vh'}, 0);
        `}
      >
        {headline && (
          <Box cols={[12, 12, 4]}>
            <h2 className={`studio__headline size--${fontsize}`}>{headline}</h2>
          </Box>
        )}
        <Box
          cols={[headline ? 11 : 12, headline ? 11 : 12, headline ? 8 : 12]}
          left={[headline ? 1 : 0, headline ? 1 : 0, 0]}
        >
          <div
            className={`studio__text size--${index === 0 ? 1 : fontsize}`}
            dangerouslySetInnerHTML={{ __html: text }}
          />
        </Box>
      </Box>
    </Box>
  )
}

const Studio = ({ data: { seo, allStudio }, location }) => {
  const [fontsize, setFontsize] = useState(0)
  const [windowWidth] = useWindowSize()
  useEffect(() => {
    if (windowWidth < 1025) {
      setFontsize(0)
    } else {
      setFontsize(1)
    }
  }, [windowWidth])
  return (
    <>
      <SEO
        title={'Studio | ' + seo.title}
        description={seo.description}
        url={location.url}
      />
      <Grid
        {...gridProps}
        gutterY=''
        top={['82vh', '82vh', '82vh', '82vh', '93vh']}
        bottom={['50vh']}
      >
        {allStudio.nodes.map(({ headline, text, id }, index) => (

          <StudioElement
            index={index}
            headline={headline}
            text={text}
            fontsize={fontsize}
          />
        ))}
      </Grid>
    </>
  )
}

export default Studio

export const query = graphql`
  query {
    seo {
      title
      description
    }
    allStudio {
      nodes {
        id
        headline
        text
      }
    }
  }
`
